<template>
  <div>
    <MonthYearSelector
      @monthYearSelected="monthYearSelected"
      :isLoadingLeft="isLoadingLeft"
      :isLoadingRight="isLoadingRight"
      :showMonthSelector="true"
      :showYearSelector="true"
      :month="settings.month"
      :year="settings.year"
    />

    <section class="info-tiles has-margin-bottom">
      <div class="container">
        <div class="tile is-ancestor has-text-centered">
          <div class="tile is-parent">
            <article class="tile is-child box">
              <p class="title">
                <AnimatedNumber
                  :value="nrOfLocationsWithoutInvoice"
                  :speed="7"
                />
              </p>
              <p class="subtitle">Without invoice</p>
            </article>
          </div>
          <div class="tile is-parent">
            <article class="tile is-child box">
              <p class="title">
                <AnimatedNumber :value="totalSum" :speed="7" />
              </p>
              <p class="subtitle">Total revenu</p>
            </article>
          </div>
          <div class="tile is-parent">
            <article class="tile is-child box">
              <p class="title">
                <AnimatedNumber :value="totalPaid" :speed="7" />
              </p>
              <p class="subtitle">Total paid</p>
            </article>
          </div>
          <div class="tile is-parent">
            <article class="tile is-child box">
              <!-- <p class="title">{{ totalOpen | toCurrency }}</p> -->
              <p class="title">
                <AnimatedNumber :value="totalOpen" :speed="7" />
              </p>
              <p class="subtitle">Total open</p>
            </article>
          </div>
        </div>
      </div>
    </section>

    <div class="tabs is-boxed">
      <ul>
        <li
          :class="{
            'is-active': selectedStatusId === -1,
          }"
        >
          <a @click="selectedStatusId = -1" class="control"
            ><span
              >Without invoice
              <span class="ml-1" :class="{ 'is-loading': locationsLoading }"
                >({{ nrOfLocationsWithoutInvoice }})</span
              >
            </span>
          </a>
        </li>
        <li
          v-for="statusId in statusIds"
          :key="statusId"
          :class="{
            'is-active': selectedStatusId === statusId,
          }"
        >
          <a @click="selectedStatusId = statusId" class="control">
            <span
              >{{ getStatusName(statusId) }}
              <span class="ml-1" :class="{ 'is-loading': invoicesLoading }"
                >({{ getStatusTotal(statusId) }})</span
              >
            </span>
          </a>
        </li>
      </ul>
    </div>

    <hr />

    <p v-if="showProgressBar">
      <span>{{ newInvoiceCounter }} invoices created</span>
      <span>{{ createInvoiceProgress }}</span>
      <progress
        class="progress is-medium is-link"
        :value="createInvoiceProgress"
        max="100"
        >{{ createInvoiceProgress }}%</progress
      >
    </p>

    <Loader v-if="isLoading" />

    <InvoicesTable
      v-if="selectedStatusId > -1"
      :invoices="filteredInvoices"
      :locationId="locationId"
    />

    <transition name="fade" mode="out-in">
      <div v-if="isLoading">
        <ui-loader />
      </div>
    </transition>

    <div v-if="selectedStatusId === -1">
      <NoInvoiceLocations />
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { EventBus } from '@/eventbus/event-bus'
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'

const AnimatedNumber = () => import('@/components/UI/AnimatedNumber')
const Loader = () => import('@/components/UI/Loader')
const InvoicesTable = () => import('@/components/FeeInvoices/InvoicesTable')
const NoInvoiceLocations = () =>
  import('@/components/FeeInvoices/NoInvoiceLocations')
const MonthYearSelector = () => import('@/components/UI/MonthYearSelector')
//const SearchBar = () => import('@/components/SearchBar')

export default {
  components: {
    AnimatedNumber,
    InvoicesTable,
    NoInvoiceLocations,
    MonthYearSelector,
    //SearchBar,
  },

  props: {
    locationId: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      channelId: Number(this.$route.params.channelId),
      isCreating: false,
      isCreditting: false,
      isLoading: false,
      isLoadingLeft: false,
      isLoadingRight: false,
      isSearching: false,
      isSendingReminder: false,
      loadCounter: 0,
      newInvoiceCounter: 0,
      selectedInvoiceId: 0,
      showLocationsNoInvoices: false,
      showInvoices: true,
      showProgressBar: false,
      statusIds: [1, 2, 3],
      unpaidOnly: false,
      selectedStatusId: 1,
    }
  },

  computed: {
    ...mapGetters('feeinvoiceStore', ['isBusy']),
    ...mapState('feeinvoiceStore', [
      'settings',
      'locations',
      'locationsLoading',
      'invoices',
      'invoicesLoading',
    ]),

    showNoInvoicesWarning() {
      let showWarning = false

      if (
        !this.isLoading &&
        !this.isLoadingLeft &&
        !this.isLoadingRight &&
        this.loadCounter > 0 &&
        this.invoices.length === 0
      ) {
        showWarning = true
      }

      return showWarning
    },

    nrOfLocationsWithoutInvoice() {
      return this.locations.length
    },

    createInvoiceProgress() {
      let percentage = 0

      // if (this.totalLocationWithoutInvoice > 0 && this.newInvoiceCounter > 0) {
      //   percentage = Math.round(
      //     (this.newInvoiceCounter / this.totalLocationWithoutInvoice) * 100
      //   )
      // }

      return percentage
    },

    filteredInvoices() {
      let self = this
      let invoices = []

      if (self.invoices.length > 0) {
        invoices = self.invoices.filter(
          (i) => i.StatusId === this.selectedStatusId
        )

        if (self.unpaidOnly) {
          invoices = invoices.filter((i) => i.PaymentStatusId === 0)
        }
      }

      invoices.sort(function(a, b) {
        return a.Id < b.Id ? -1 : a.Id > b.Id ? 1 : 0
      })

      return invoices
    },

    totalOpen() {
      let total = 0
      if (this.filteredInvoices && this.filteredInvoices.length > 0) {
        for (let i = 0; i <= this.filteredInvoices.length; i++) {
          let invoice = this.filteredInvoices[i]
          if (
            invoice &&
            invoice.StatusId === 1 &&
            invoice.PaymentStatusId !== 3
          ) {
            total = total + invoice.TotalExclTax
          }
        }
      }
      return Math.round(total * 100) / 100
    },

    totalPaid() {
      let total = 0
      if (this.filteredInvoices && this.filteredInvoices.length > 0) {
        for (let i = 0; i <= this.filteredInvoices.length; i++) {
          let invoice = this.filteredInvoices[i]
          if (
            invoice &&
            invoice.StatusId === 1 &&
            invoice.PaymentStatusId === 3
          ) {
            total = total + invoice.TotalExclTax
          }
        }
      }
      return Math.round(total * 100) / 100
    },

    totalSum() {
      let total = 0
      if (this.filteredInvoices && this.filteredInvoices.length > 0) {
        for (let i = 0; i <= this.filteredInvoices.length; i++) {
          let invoice = this.filteredInvoices[i]
          if (invoice && invoice.StatusId === 1) {
            total = total + invoice.TotalExclTax
          }
        }
      }
      return Math.round(total * 100) / 100
    },
  },

  watch: {
    nrOfLocationsWithoutInvoice(newVal) {
      if (newVal === 0) {
        this.selectedStatusId = 1
      }
    },
  },

  created() {
    this.settings.locationId = this.locationId
    this.settings.channelId = this.channelId
    this.updateSettings(this.settings)
  },

  mounted() {},

  beforeDestroy() {},

  methods: {
    ...mapActions('feeinvoiceStore', ['updateSettings', 'getAllInvoices']),
    ...mapMutations('feeinvoiceStore', ['setLocations', 'setInvoices']),

    monthYearSelected(data) {
      let self = this

      self.settings.month = data.month
      self.settings.year = data.year

      this.newInvoiceCounter = 0
      this.showProgressBar = false

      this.updateSettings(this.settings)
    },

    goToInvoice(invoiceId) {
      this.$router.push({ path: `/invoices/${invoiceId}` })
    },

    goToLocation(locationId) {
      this.$router.push({ path: `/locations/${locationId}` })
    },

    getStatusName(statusId) {
      let name = ''

      switch (statusId) {
        case 1:
          name = 'Final'
          break
        case 2:
          name = 'Frozen'
          break
        case 3:
          name = 'Credit'
          break
      }

      return name
    },

    getStatusTotal(statusId) {
      return this.invoices.filter((i) => i.StatusId === statusId).length
    },
  },
}
</script>
